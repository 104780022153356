export const devFirebaseConfig = {
	apiKey: "AIzaSyA_buA-sRAROHiRNbSu8zbI2h-DtGi9dBI",
	authDomain: "azt-admin.haiidev.co.kr",
	projectId: "haii-d-prj-azt",
	storageBucket: "haii-d-prj-azt.appspot.com",
	messagingSenderId: "1024816742292",
	appId: "1:1024816742292:web:0ab8ea274fba92802c5981",
	measurementId: "G-3DQXX28D9F",
};

export const prodFirebaseConfig = {
	apiKey: "AIzaSyB09UA1TmCa7D4v2SPeSDHJ-qrPhCGiUe8",
	authDomain: "azt-admin.haii.io",
	projectId: "haii-p-prj-azt",
	storageBucket: "haii-p-prj-azt.firebasestorage.app",
	messagingSenderId: "183198150820",
	appId: "1:183198150820:web:c2266baaad12efbafeb631",
	measurementId: "G-E4QLXKLW9Z",
};

export const devD20FirebaseConfig = {
	apiKey: "AIzaSyDCwWYceISoVbZBb1pws2ftUq1vroM5xhA",
	authDomain: "azt-admin-d20.haiidev.co.kr",
	projectId: "haii-d-prj-azt-d20",
	storageBucket: "haii-d-prj-azt-d20.appspot.com",
	messagingSenderId: "1046978745504",
	appId: "1:1046978745504:web:9d68c4b08c2c4e05d725c5",
};

export const prodD20FirebaseConfig = {
	apiKey: "AIzaSyB3bFR744Fv4BLlZgZCWIJKpbe4yQHxd9k",
	authDomain: "azt-admin-d20.haii.io",
	projectId: "haii-p-prj-azt-d20",
	storageBucket: "haii-p-prj-azt-d20.appspot.com",
	messagingSenderId: "493087889060",
	appId: "1:493087889060:web:ea4412306507a60a36b831",
};
