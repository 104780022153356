import { ensure } from "@/lib/async";
import store from "@/store";
import { ActionTypes } from "@/store/actions";
import util from "@/plugins/haii/util";
import { adminInterface } from "@/store/state";
import firebase from "@/plugins/firebase/firebase";
import {
  getAuth,
  EmailAuthProvider,
  GoogleAuthProvider,
  signInWithRedirect,
  getRedirectResult,
  onAuthStateChanged,
  UserCredential,
} from "firebase/auth";
import * as firebaseui from "firebaseui";
import "firebaseui/dist/firebaseui.css";
import firebaseAnalytics from "@/plugins/firebase/analytics";

class AuthClass {
  private static auth = () => {
    const auth = getAuth(firebase);
    auth.languageCode = "ko";
    return auth;
  };

  static init = async (): Promise<void> => {
    const sn = await getRedirectResult(AuthClass.auth());

    onAuthStateChanged(AuthClass.auth(), (firebaseUser): void => {
      if (process.env.VUE_APP_ENV === "localhost") {
        store.dispatch(ActionTypes.SIGN_IN, {
          "uid": "PAxm6pBIdXbm5ggWYaaLX6WXCEx2",
          "name": "이민영",
          "email": "min0@haii.io",
          "photoURL": "https://lh3.googleusercontent.com/a/ACg8ocIpZiEeC3PtpQvrDmQ6RewOUp10vg4q2-Oqc5aXNRPZnNRZyQB8=s96-c"
        });
        return;
      }
      if (!firebaseUser) {
        ensure(store.dispatch(ActionTypes.SIGN_OUT));
        return;
      }

      const admin = AuthClass.extractProviderData(firebaseUser);

      // 구글 로그인 시
      if (firebaseUser.providerData[0].providerId == "google.com") {
        // @haii.io 로 끝나지않으면 return
        if (!AuthClass.validateLoginUser(admin, sn)) return;
      }

      // 최초 로그인만 작동
      if (!store.state.admin.email) {
        firebaseAnalytics.setFirebaseUserId(firebaseUser.uid);
        firebaseAnalytics.loginEvent(firebaseUser.providerData[0].providerId);
      }

      ensure(store.dispatch(ActionTypes.SIGN_IN, admin));
    });
  };

  private static extractProviderData = (firebaseUser): adminInterface => {
    const admin: adminInterface = {
      uid: firebaseUser.uid,
    };

    firebaseUser.providerData.forEach((profile) => {
      if (!profile) {
        return;
      }
      if (profile.displayName) {
        admin.name = profile.displayName;
      }
      if (profile.email) {
        admin.email = profile.email;
      } else {
        if (firebaseUser.email) admin.email = firebaseUser.email;
      }
      if (profile.photoURL) {
        admin.photoURL = profile.photoURL;
      }
    });

    return admin;
  };

  static updateSignInUI = (containerID: string, label: string): void => {
    const ui =
      firebaseui.auth.AuthUI.getInstance() ||
      new firebaseui.auth.AuthUI(AuthClass.auth());

    ui.start(containerID, {
      signInOptions: [
        {
          provider: EmailAuthProvider.PROVIDER_ID,
          disableSignUp: {
            status: true,
            adminEmail: "(주)haii",
          },
          fullLabel: label,
          requireDisplayName: false,
        },
      ],
      callbacks: {
        // 로그인이 성공하면,
        signInSuccessWithAuthResult: (authResult, redirectUrl) => {
          // 로그인 정보를 각각의 data에 저장한다.
          return false;
        },
      },
    });
  };

  static googleLogin = async (): Promise<void> => {
    const provider = new GoogleAuthProvider();
    provider.addScope("email");
    provider.setCustomParameters({ prompt: "select_account" });

    await signInWithRedirect(AuthClass.auth(), provider);
  };

  static validateLoginUser = (
    user: adminInterface,
    sn: UserCredential | null
  ): boolean => {
    if (user && user.email && user.email.toLowerCase().endsWith("@haii.io")) {
      if (sn) {
        util.toastMessage("success", "로그인 완료");
      }
      return true;
    } else {
      ensure(
        AuthClass.auth()
          .signOut()
          .then(() => {
            util.toastMessage("error", "허용된 로그인 방식이 아닙니다.");
          })
      );
      return false;
    }
  };

  static signOut = (): void => {
    ensure(
      AuthClass.auth()
        .signOut()
        .then(() => {
          util.toastMessage("success", "로그아웃 됐습니다.");
        })
    );
  };
}

export default AuthClass;
